<template>
  <div>
    <a-form-model layout="inline" @keyup.enter.native="query">
      <a-row>
        <a-col :span="22">
          <a-form-model-item>
            <a-input v-model="form.code" placeholder="档案编号" />
          </a-form-model-item>
          <a-form-model-item>
            <a-input v-model="form.name" placeholder="档案名称" />
          </a-form-model-item>
          <a-form-model-item>
            <a-input v-model="form.keyWord" placeholder="关键词" />
          </a-form-model-item>

          <a-form-model-item>
            <a-cascader v-model="form.type" placeholder="档案类型" :options="typeList" :fieldNames="{
              label: 'name',
              value: 'value',
              children: 'children',
            }" />
          </a-form-model-item>

          <a-form-model-item>
            <a-select v-model="form.level" style="width: 180px" placeholder="保密级别">
              <a-select-option v-for="item in levelList" :key="item.value" :value="item.value">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item>
            <a-select v-model="form.transfereeDeptId" style="width: 180px" placeholder="移交部门">
              <a-select-option v-for="item in organizationList" :key="item.id" :value="item.id">{{ item.uniqueName
              }}</a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item>
            <a-space>
              <a-button type="primary" @click="query">查询</a-button>
              <a-button @click="reset">重置</a-button>
            </a-space>
          </a-form-model-item>
        </a-col>

        <a-col :span="2">
          <div class="right" style="margin-top: 4px">
            <a-button type="primary" v-if="$getPermission($route.path + '/add')"
              @click="$router.push($route.path + '/add')">
              新建档案
            </a-button>
          </div>
        </a-col>
      </a-row>
    </a-form-model>

    <Padding />

    <a-table bordered :dataSource="list" :loading="loading" @change="onChange" :pagination="{
      total,
      current,
      pageSize,
      showTotal: (total) => `共 ${total} 条记录`,
    }">
      <a-table-column title="档案编号" data-index="code"> </a-table-column>

      <a-table-column title="档案名称" data-index="name" />

      <a-table-column title="类型">
        <template slot-scope="text">
          {{ getTypeText(text.type) }}
        </template>
      </a-table-column>
      <a-table-column title="保密级别" align="center">
        <template slot-scope="text">
          <DataDictFinder dictType="secretLevel" :dictValue="text.level" />
        </template>
      </a-table-column>

      <a-table-column title="移交时间" data-index="createAt" align="center">
      </a-table-column>
      <a-table-column title="移交部门" data-index="transfereeDeptName" align="center">
      </a-table-column>

      <a-table-column title="状态" align="center">
        <template slot-scope="text">
          <DataDictFinder dictType="arcStatus" :dictValue="text.status" iconType="text" />
        </template>
      </a-table-column>

      <a-table-column title="附件">
        <template slot-scope="text">
          <div>
            <DownloadLink :list="text.fileList" />
          </div>
        </template>
      </a-table-column>

      <a-table-column title="操作" align="right" width="120px" v-if="$getPermission($route.path + '/edit')">
        <template slot-scope="text">
          <a-space>
            <a href="#" @click.prevent="printBarCode(text)"> 打印条码 </a>
            <a-dropdown>
              <a @click="(e) => e.preventDefault()">
                更多 <a-icon type="down" />
              </a>
              <a-menu slot="overlay">



                <a-menu-item @click="onReceive(text)" v-if="text.status === 'toReceive'">
                  <span class="link">接收</span>
                </a-menu-item>
                <a-menu-item @click="edit(text)">
                  <span class="link">编辑</span>
                </a-menu-item>
                <a-menu-item @click="deleteText(text)">
                  <span class="danger">删除</span>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </a-space>
        </template>
      </a-table-column>
    </a-table>
  </div>
</template>

<script>
import organization from "@/mixins/organization";
import archiveType from "@/mixins/archive-type";
import DownloadLink from "@/components/download-link";
import { fetchList, remove, receive } from "@/api/archives";

import JsBarcode from "jsbarcode";

export default {
  mixins: [organization, archiveType],

  components: { DownloadLink },

  data() {
    return {
      form: {},

      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,
    };
  },

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      const { current, pageSize, form } = this;

      let type = Array.isArray(form.type) ? form.type.join(",") : undefined;

      this.loading = true;
      fetchList({
        pageNum: current,
        pageSize,
        ...form,
        type,
      })
        .then((res) => {
          this.loading = false;
          if (Array.isArray(res.list)) {
            this.list = res.list;
            this.total = res.totalSize;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },
    edit(text) {
      window.localStorage.setItem("edit-archive", JSON.stringify(text));
      this.$router.push(this.$route.path + `/edit`);
    },

    deleteText(text) {
      const that = this;
      this.$confirm({
        title: "确认要删除这份档案吗？",
        onOk() {
          remove({
            id: text.id,
          }).then(() => {
            that.getList();
          });
        },
      });
    },

    onReceive(text) {
      const that = this;
      this.$confirm({
        title: "确认要接收这份档案吗？",
        onOk() {
          receive({
            ...text,
          }).then(() => {
            that.getList();
          });
        },
      });
    },




    query() {
      this.current = 1;
      this.getList();
    },
    reset() {
      this.form = {};
      this.current = 1;
      this.getList();
    },

    printBarCode(item) {
      console.log(item.code);
      const code = item.code ? item.code : "";
      const canvas = document.createElement("canvas");
      JsBarcode(canvas, `${code}`, {
        // format: "CODE39",
        // lineColor: "#000",
        width: 2,
        height: 100,
        margin: 0,
        displayValue: false,
      });
      const imagePath = canvas.toDataURL("image/png");
      const img = `
        <div>${item.name}</div>  
        <img src="${imagePath}" />
        <div>${item.code}</div>  
`;

      console.log("img path", imagePath);

      var iframe = window.document.createElement("iframe");
      iframe.setAttribute(
        "style",
        "position:absolute;width:0px;height:0px;left:-500px;top:-500px;"
      );
      window.document.body.appendChild(iframe);
      var doc = iframe.contentWindow.document;
      doc.write(img);

      var ys = `body{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      img {
        width: 98%;
      }
      div{text-align: center;
        font-size: 8px;
      }`;
      var style = window.document.createElement("style");
      style.innerText = ys;
      doc.getElementsByTagName("head")[0].appendChild(style);
      // doc.close();

      setTimeout(() => {
        iframe.contentWindow.focus();
        iframe.contentWindow.print();
      }, 0);
    },
  },
};
</script>