<template>
  <div>
    <a-button type="primary" @click="visible = true"> 档案归还 </a-button>

    <a-modal
      :visible="visible"
      title="档案归还"
      @ok="ok"
      @cancel="cancel"
      okText="确认归还"
      width="800px"
      :okButtonProps="{
        props: {
          loading,
        },
      }"
    >
      <a-input-search
        placeholder="请输入档案编号或使用扫码枪扫描"
        enter-button="添加"
        v-model="searchValue"
        :loading="addLoading"
        @search="onSearch"
        style="margin-bottom: 24px"
      />

      <Padding size="large" />

      <!-- <div class="left" style="margin-bottom: 16px">
        <div>出借人：{{ item.borrowerName }}</div>
        <div style="margin-left: 24px">出借部门：{{ item.deptName }}</div>
        <div style="margin-left: 24px" class="left">
          <span>申请状态：</span>
          <span style="color: #1890ff; font-weight: bold">
            <DataDictFinder
              dictType="arcBorrowStatus"
              :dictValue="item.status"
            />
          </span>
        </div>

        <div style="margin-left: auto">
          <Option :list="list" @add="add" />
        </div>
      </div> -->

      <a-table bordered :dataSource="list" :pagination="false">
        <!-- :row-selection="{
          selectedRowKeys,
          onChange: onSelectChange,
        }" -->
        <a-table-column title="档案编号" data-index="code" ellipsis="true">
        </a-table-column>

        <a-table-column title="档案名称" data-index="name" ellipsis="true" />

        <a-table-column title="类型" align="center">
          <template slot-scope="text">
            {{ getTypeText(text.type) }}
          </template>
        </a-table-column>
        <a-table-column title="保密级别" align="center">
          <template slot-scope="text">
            <DataDictFinder dictType="secretLevel" :dictValue="text.level" />
          </template>
        </a-table-column>

        <a-table-column title="档案位置" data-index="location" align="center">
        </a-table-column>

        <a-table-column title="档案状态" align="center">
          <template slot-scope="text">
            <DataDictFinder
              dictType="arcStatus"
              :dictValue="text.status"
              iconType="text"
            />
          </template>
        </a-table-column>

        <a-table-column title="操作" align="center">
          <template slot-scope="text, record, index">
            <a href="#" class="danger" @click.prevent="remove(index)"> 移除 </a>
          </template>
        </a-table-column>
      </a-table>
    </a-modal>
  </div>
</template>

<script>
import { fetchList, backArchive } from "@/api/archives";
export default {
  data() {
    return {
      visible: false,
      loading: false,
      list: [],

      searchValue: "",
      addLoading: false,
    };
  },

  methods: {
    cancel() {
      this.visible = false;
    },
    ok() {
      if (this.list.length === 0) {
        this.$message.error("请添加待归还档案");
        return;
      }

      const that = this;
      this.$confirm({
        title: "确认归还吗？",
        onOk() {
          that.loading = true;
          backArchive(that.list.map((item) => item.code))
            .then(() => {
              that.visible = false;
              that.list = [];
              that.$emit("refresh");
            })
            .finally(() => {
              that.loading = false;
            });
        },
      });
    },

    onSearch(code) {
      if (code) {
        this.addLoading = true;
        fetchList({
          code,
        })
          .then((res) => {
            if (Array.isArray(res.list) && res.list.length > 0) {
              const result = res.list[0];

              if (result.status !== "borrowing") {
                this.$message.error("请添加出借中档案");
                return;
              }
              if (this.list.find((item) => item.id === result.id)) {
                this.$message.error("该档案已在待归还列表中");
                return;
              }
              this.list.push(result);
            } else {
              this.$message.error("查询不到结果");
            }
          })
          .finally(() => {
            this.addLoading = false;
            this.searchValue = "";
          });
      }
    },

    getTypeText(type) {
      if (typeof type === "string") {
        const arr = type.split(",");
        return arr
          .map((item) => {
            const obj = this.flatTypeList.find(
              (element) => element.value === item
            );
            return obj ? obj.name : "";
          })
          .join(" - ");
      } else {
        return "";
      }
    },

    remove(index) {
      this.list.splice(index, 1);
    },
  },
};
</script>