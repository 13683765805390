var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();_vm.visible = true}}},[_vm._v("档案出借")]),_c('a-modal',{attrs:{"visible":_vm.visible,"title":"档案出借","okText":"确认出借","width":"800px","okButtonProps":{
      props: {
        loading: _vm.loading,
      },
    }},on:{"ok":_vm.ok,"cancel":_vm.cancel}},[_c('Option',{attrs:{"list":_vm.list},on:{"add":_vm.add}}),_c('div',{staticClass:"left",staticStyle:{"margin-bottom":"16px"}},[_c('div',[_vm._v("出借人："+_vm._s(_vm.item.borrowerName))]),_c('div',{staticStyle:{"margin-left":"24px"}},[_vm._v("出借部门："+_vm._s(_vm.item.deptName))]),_c('div',{staticClass:"left",staticStyle:{"margin-left":"24px"}},[_c('span',[_vm._v("申请状态：")]),_c('span',{staticStyle:{"color":"#1890ff","font-weight":"bold"}},[_c('DataDictFinder',{attrs:{"dictType":"arcBorrowStatus","dictValue":_vm.item.status}})],1)])]),_c('a-table',{attrs:{"bordered":"","dataSource":_vm.list,"pagination":false,"row-selection":{
        selectedRowKeys: _vm.selectedRowKeys,
        onChange: _vm.onSelectChange,
      }}},[_c('a-table-column',{attrs:{"title":"档案编号","data-index":"code","ellipsis":"true"}}),_c('a-table-column',{attrs:{"title":"档案名称","data-index":"name","ellipsis":"true"}}),_c('a-table-column',{attrs:{"title":"类型","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_vm._v(" "+_vm._s(_vm.getTypeText(text.type))+" ")]}}])}),_c('a-table-column',{attrs:{"title":"保密级别","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('DataDictFinder',{attrs:{"dictType":"secretLevel","dictValue":text.level}})]}}])}),_c('a-table-column',{attrs:{"title":"档案位置","data-index":"location","align":"center"}}),_c('a-table-column',{attrs:{"title":"档案状态","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('DataDictFinder',{attrs:{"dictType":"arcStatus","dictValue":text.status,"iconType":"text"}})]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }