<template>
  <span>
    <a href="#" @click.prevent="visible = true">档案清单</a>

    <a-modal
      :visible="visible"
      title="档案清单"
      :footer="null"
      width="800px"
      @cancel="visible = false"
    >
      <a-table bordered :dataSource="list" :pagination="false">
        <a-table-column title="档案编号" data-index="code" ellipsis="true">
        </a-table-column>

        <a-table-column title="档案名称" data-index="name" ellipsis="true" />

        <a-table-column title="类型" align="center">
          <template slot-scope="text">
            {{ getTypeText(text.type) }}
          </template>
        </a-table-column>
        <a-table-column title="保密级别" align="center">
          <template slot-scope="text">
            <DataDictFinder dictType="secretLevel" :dictValue="text.level" />
          </template>
        </a-table-column>

        <a-table-column title="档案位置" data-index="location" align="center">
        </a-table-column>

        <a-table-column title="档案状态" align="center">
          <template slot-scope="text">
            <DataDictFinder
              dictType="arcStatus"
              :dictValue="text.status"
              iconType="text"
            />
          </template>
        </a-table-column>
      </a-table>
    </a-modal>
  </span>
</template>


<script>
import { flatList } from "@/utils/flat";
import { mapGetters } from "vuex";
import { fetchBorrowDetail } from "@/api/archives";
export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      visible: false,
      detail: {},
    };
  },

  watch: {
    visible(newValue) {
      if (newValue) {
        fetchBorrowDetail({
          id: this.item.id,
        }).then((res) => {
          if (res) {
            this.detail = res;
            if (Array.isArray(res.borrowArchiveList)) {
              this.selectedRowKeys = res.borrowArchiveList.map(
                (item) => item.id
              );
            }
          }
        });
      }
    },
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    typeList() {
      return this.findDataDict("arcType");
    },

    flatTypeList() {
      let result = [];
      flatList(this.typeList, result);
      return result;
    },

    // 档案列表
    list() {
      return Array.isArray(this.detail.borrowArchiveList)
        ? this.detail.borrowArchiveList.map((item) => {
            return {
              ...item,
              key: item.id,
            };
          })
        : [];
    },
  },

  methods: {
    getTypeText(type) {
      if (typeof type === "string") {
        const arr = type.split(",");
        return arr
          .map((item) => {
            const obj = this.flatTypeList.find(
              (element) => element.value === item
            );
            return obj ? obj.name : "";
          })
          .join(" - ");
      } else {
        return "";
      }
    },
  },
};
</script>