<template>
  <div>
    <a-input-search
      placeholder="请输入档案编号或使用扫码枪扫描"
      enter-button="添加"
      v-model="searchValue"
      :loading="loading"
      @search="onSearch"
      style="margin-bottom: 24px"
    />
  </div>
</template>

<script>
import { fetchList } from "@/api/archives";
import { mapGetters } from "vuex";
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      loading: false,
      searchValue: "",
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
  },

  methods: {
    onSearch(code) {
      if (code) {
        this.loading = true;
        fetchList({
          code,
        })
          .then((res) => {
            if (Array.isArray(res.list) && res.list.length > 0) {
              const result = res.list[0];

              if (result.status !== "inlib") {
                this.$message.error("请添加在库档案");
                return;
              }
              if (this.list.find((item) => item.id === result.id)) {
                this.$message.error("该档案已在待出借列表中");
                return;
              }
              this.$emit("add", result);
            } else {
              this.$message.error("查询不到结果");
            }
          })
          .finally(() => {
            this.searchValue = "";
            this.loading = false;
          });
      }
    },
  },
};
</script>