<template>
  <div>
    <a-form-model layout="inline" @keyup.enter.native="query">
      <a-row>
        <a-col :span="22">
          <a-form-model-item>
            <a-input v-model="form.code" placeholder="档案编号" />
          </a-form-model-item>
          <a-form-model-item>
            <a-input v-model="form.name" placeholder="档案名称" />
          </a-form-model-item>

          <a-form-model-item>
            <a-cascader
              v-model="form.type"
              placeholder="档案类型"
              :options="typeList"
              :fieldNames="{
                label: 'name',
                value: 'value',
                children: 'children',
              }"
            />
          </a-form-model-item>

          <a-form-model-item>
            <a-select
              v-model="form.level"
              style="width: 180px"
              placeholder="保密级别"
            >
              <a-select-option
                v-for="item in levelList"
                :key="item.value"
                :value="item.value"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item>
            <a-select
              v-model="form.transfereeDeptId"
              style="width: 180px"
              placeholder="移交部门"
            >
              <a-select-option
                v-for="item in organizationList"
                :key="item.id"
                :value="item.id"
                >{{ item.uniqueName }}</a-select-option
              >
            </a-select>
          </a-form-model-item>

          <a-form-model-item>
            <a-select
              style="width: 180px"
              placeholder="档案出借状态"
              v-model="form.status"
            >
              <a-select-option
                v-for="item in archiveBorrowStatusList"
                :key="item.value"
                :value="item.value"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-model-item>

          <a-form-model-item>
            <a-space>
              <a-button @click="query" type="primary">查询</a-button>
              <a-button @click="reset">重置</a-button>
            </a-space>
          </a-form-model-item>
        </a-col>

        <a-col :span="2">
          <div class="right" style="margin-top: 4px">
            <Back @refresh="getList" />
          </div>
        </a-col>
      </a-row>
    </a-form-model>

    <Padding />

    <a-table
      bordered
      :dataSource="list"
      :loading="loading"
      @change="onChange"
      :pagination="{
        total,
        current,
        pageSize,
        showTotal: (total) => `共 ${total} 条记录`,
      }"
    >
      <a-table-column title="档案编号" data-index="code"> </a-table-column>

      <a-table-column title="档案名称" data-index="name" />

      <a-table-column title="档案类型">
        <template slot-scope="text">
          {{ getTypeText(text.type) }}
        </template>
      </a-table-column>
      <a-table-column title="保密级别" align="center">
        <template slot-scope="text">
          <DataDictFinder dictType="secretLevel" :dictValue="text.level" />
        </template>
      </a-table-column>

      <a-table-column title="档案出借状态" align="center">
        <template slot-scope="text">
          <DataDictFinder
            dictType="arcBorrowArcStatus"
            :dictValue="text.status"
            iconType="text"
          />
        </template>
      </a-table-column>

      <a-table-column title="出借人" data-index="borrowerName" align="center">
      </a-table-column>

      <a-table-column title="出借部门" data-index="deptName" align="center">
      </a-table-column>

      <a-table-column title="出借时间" data-index="borrowedDate" align="center">
      </a-table-column>
      <a-table-column
        title="预计归还时间"
        data-index="expectReturnDate"
        align="center"
      >
      </a-table-column>
      <a-table-column
        title="实际归还时间"
        data-index="actualReturnDate"
        align="center"
      >
      </a-table-column>

      <!-- v-if="$getPermission($route.path + '/back')" -->
      <a-table-column title="操作" align="right">
        <template slot-scope="text">
          <a-space>
            <a
              href="#"
              class="danger"
              v-if="text.status === 'borrowing' && text.status === 'overdue'"
              @click.prevent="revert(text)"
              >归还</a
            >
          </a-space>
        </template>
      </a-table-column>
    </a-table>
  </div>
</template>

<script>
import organization from "@/mixins/organization";
import archiveType from "@/mixins/archive-type";
import { fetchBorrowArchiveList, backArchive } from "@/api/archives";
import { mapGetters } from "vuex";

import Back from "./back.vue";

export default {
  mixins: [organization, archiveType],

  components: {
    Back,
  },

  data() {
    return {
      form: {},

      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    archiveBorrowStatusList() {
      return this.findDataDict("arcBorrowArcStatus");
    },
  },

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      const { current, pageSize, form } = this;

      let type = Array.isArray(form.type) ? form.type.join(",") : undefined;

      this.loading = true;
      fetchBorrowArchiveList({
        pageNum: current,
        pageSize,
        ...form,
        type,
      })
        .then((res) => {
          this.loading = false;
          if (Array.isArray(res.list)) {
            this.list = res.list;
            this.total = res.totalSize;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    query() {
      this.current = 1;
      this.getList();
    },
    reset() {
      this.form = {};
      this.current = 1;
      this.getList();
    },

    revert(text) {
      const that = this;
      this.$confirm({
        title: "确认归还吗？",
        onOk() {
          backArchive([text.code]).then(() => {
            that.getList();
          });
        },
      });
    },
  },
};
</script>