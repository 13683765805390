<template>
  <span>
    <a href="#" @click.prevent="visible = true">档案出借</a>

    <a-modal
      :visible="visible"
      title="档案出借"
      @ok="ok"
      @cancel="cancel"
      okText="确认出借"
      width="800px"
      :okButtonProps="{
        props: {
          loading,
        },
      }"
    >
      <Option :list="list" @add="add" />

      <div class="left" style="margin-bottom: 16px">
        <div>出借人：{{ item.borrowerName }}</div>
        <div style="margin-left: 24px">出借部门：{{ item.deptName }}</div>
        <div style="margin-left: 24px" class="left">
          <span>申请状态：</span>
          <span style="color: #1890ff; font-weight: bold">
            <DataDictFinder
              dictType="arcBorrowStatus"
              :dictValue="item.status"
            />
          </span>
        </div>
      </div>

      <a-table
        bordered
        :dataSource="list"
        :pagination="false"
        :row-selection="{
          selectedRowKeys,
          onChange: onSelectChange,
        }"
      >
        <a-table-column title="档案编号" data-index="code" ellipsis="true">
        </a-table-column>

        <a-table-column title="档案名称" data-index="name" ellipsis="true" />

        <a-table-column title="类型" align="center">
          <template slot-scope="text">
            {{ getTypeText(text.type) }}
          </template>
        </a-table-column>
        <a-table-column title="保密级别" align="center">
          <template slot-scope="text">
            <DataDictFinder dictType="secretLevel" :dictValue="text.level" />
          </template>
        </a-table-column>

        <a-table-column title="档案位置" data-index="location" align="center">
        </a-table-column>

        <a-table-column title="档案状态" align="center">
          <template slot-scope="text">
            <DataDictFinder
              dictType="arcStatus"
              :dictValue="text.status"
              iconType="text"
            />
          </template>
        </a-table-column>
      </a-table>
    </a-modal>
  </span>
</template>


<script>
import Option from "./option.vue";
import { flatList } from "@/utils/flat";
import { mapGetters } from "vuex";
import { fetchBorrowDetail, confirmBorrow } from "@/api/archives";
export default {
  components: {
    Option,
  },

  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      visible: false,
      detail: {},
      selectedRowKeys: [], // 选中的档案 id
      loading: false,
    };
  },

  watch: {
    visible(newValue) {
      if (newValue) {
        fetchBorrowDetail({
          id: this.item.id,
        }).then((res) => {
          if (res) {
            this.detail = res;
            if (Array.isArray(res.borrowArchiveList)) {
              this.selectedRowKeys = res.borrowArchiveList.map(
                (item) => item.id
              );
            }
          }
        });
      }
    },
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    typeList() {
      return this.findDataDict("arcType");
    },

    flatTypeList() {
      let result = [];
      flatList(this.typeList, result);
      return result;
    },

    // 档案列表
    list() {
      return Array.isArray(this.detail.borrowArchiveList)
        ? this.detail.borrowArchiveList.map((item) => {
            return {
              ...item,
              key: item.id,
            };
          })
        : [];
    },
  },

  methods: {
    getTypeText(type) {
      if (typeof type === "string") {
        const arr = type.split(",");
        return arr
          .map((item) => {
            const obj = this.flatTypeList.find(
              (element) => element.value === item
            );
            return obj ? obj.name : "";
          })
          .join(" - ");
      } else {
        return "";
      }
    },

    onSelectChange(keys) {
      this.selectedRowKeys = keys;
    },

    add(result) {
      if (Array.isArray(this.detail.borrowArchiveList)) {
        this.detail.borrowArchiveList.push(result);
      } else {
        this.detail = {
          ...this.detail,
          borrowArchiveList: [result],
        };
      }
    },

    ok() {
      const arr = [];
      this.selectedRowKeys.forEach((key) => {
        const obj = this.list.find((item) => item.key === key);
        if (obj) {
          arr.push(obj);
        }
      });

      if (arr.length === 0) {
        this.$message.error("请选择档案");
        return;
      }
      this.loading = true;

      confirmBorrow({
        id: this.item.id,
        borrowArchiveList: arr.map((archive) => {
          return {
            borrowId: this.item.id,
            arcId: archive.id,
            status: archive.status,
          };
        }),
      })
        .then(() => {
          this.$emit("refresh");
        })
        .finally(() => {
          this.loading = false;
        });

      this.cancel();
    },
    cancel() {
      this.visible = false;
    },
  },
};
</script>