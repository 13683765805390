<template>
  <div>
    <Pane :bottom="0" :showRemarks="false">
      <a-tabs
        :tabBarStyle="{
          margin: '0',
        }"
        v-model="active"
      >
        <a-tab-pane
          v-for="item in [
            { name: '所有档案', value: '1' },
            { name: '出借申请', value: '2' },
            { name: '出借记录', value: '3' },
          ]"
          :key="item.value"
          :tab="item.name"
        >
        </a-tab-pane>
      </a-tabs>
    </Pane>

    <a-card class="container">
      <template v-if="active === '1'">
        <All />
      </template>
      <template v-else-if="active === '2'">
        <LendApply />
      </template>
      <template v-else-if="active === '3'">
        <LendLog />
      </template>
    </a-card>
  </div>
</template>

<script>
import watermark from "@/mixins/watermark";
import All from "./components/all";
import LendApply from "./components/lend-apply";
import LendLog from "./components/lend-log";
export default {
  mixins: [watermark],

  components: {
    All,
    LendApply,
    LendLog,
  },

  data() {
    return {
      active: "1",
    };
  },
};
</script>