<template>
  <div>
    <a-form-model layout="inline" @keyup.enter.native="query">
      <a-row>
        <a-col :span="22">
          <a-form-model-item>
            <a-input v-model="form.borrowerName" placeholder="出借人" />
          </a-form-model-item>

          <a-form-model-item>
            <a-select
              placeholder="出借部门"
              show-search
              :filter-option="$selectFilterOption"
              style="width: 240px"
              v-model="form.deptId"
            >
              <a-select-option
                v-for="item in organizationList"
                :key="item.id"
                :value="item.id"
                >{{ item.uniqueName }}</a-select-option
              >
            </a-select>
          </a-form-model-item>

          <a-form-model-item>
            <a-select
              style="width: 180px"
              v-model="form.status"
              placeholder="状态"
            >
              <a-select-option
                v-for="item in statusList"
                :key="item.value"
                :value="item.value"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-model-item>

          <a-form-model-item>
            <a-space>
              <a-button @click="query" type="primary">查询</a-button>
              <a-button @click="reset">重置</a-button>
            </a-space>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>

    <Padding />

    <a-table
      bordered
      :dataSource="list"
      :loading="loading"
      @change="onChange"
      :pagination="{
        total,
        current,
        pageSize,
        showTotal: (total) => `共 ${total} 条记录`,
      }"
    >
      <a-table-column
        title="审批通过时间"
        data-index="approvedDate"
        align="center"
      />

      <a-table-column title="出借人" data-index="borrowerName" align="center">
      </a-table-column>
      <a-table-column title="出借部门" data-index="deptName" align="center">
      </a-table-column>
      <a-table-column title="备注" data-index="remarks"> </a-table-column>

      <a-table-column title="出借状态" align="center">
        <template slot-scope="text">
          <DataDictFinder dictType="arcBorrowStatus" :dictValue="text.status" />
        </template>
      </a-table-column>

      <a-table-column title="借出时间" data-index="borrowedDate" align="center">
      </a-table-column>

      <a-table-column
        title="预计归还时间"
        data-index="expectReturnDate"
        align="center"
      >
      </a-table-column>

      <a-table-column title="操作" align="right">
        <template slot-scope="text">
          <a-space>
            <ArchiveList v-if="text.status === 'borrowed'" :item="text" />
            <template v-if="text.status === 'tobeborrow'">
              <Borrow :item="text" @refresh="refresh" />
              <!-- v-if="$getPermission($route.path + '/cancel')" -->
              <a href="#" class="danger" @click.prevent="cancel(text)">作废</a>
            </template>
          </a-space>
        </template>
      </a-table-column>
    </a-table>
  </div>
</template>

<script>
import organization from "@/mixins/organization";
import ArchiveList from "./components/archive-list.vue";
import Borrow from "./components/borrow.vue";
import { mapGetters } from "vuex";
import { fetchBorrowList, cancelBorrow } from "@/api/archives";
export default {
  mixins: [organization],

  components: {
    ArchiveList,
    Borrow,
  },

  data() {
    return {
      form: {},
      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    statusList() {
      return this.findDataDict("arcBorrowStatus");
    },
  },

  mounted() {
    this.getList();
  },

  methods: {
    // 确认出借后刷新数据
    refresh() {
      this.getList();
    },

    getList() {
      const { current, pageSize, form } = this;

      this.loading = true;
      fetchBorrowList({
        pageNum: current,
        pageSize,
        ...form,
      })
        .then((res) => {
          this.loading = false;
          if (Array.isArray(res.list)) {
            this.list = res.list;
            this.total = res.totalSize;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    cancel(text) {
      const that = this;
      this.$confirm({
        title: "确认要作废这条出借申请记录吗？",
        onOk() {
          console.log(that, text);
          cancelBorrow({
            id: text.id,
          }).then(() => {
            that.getList();
          });
        },
      });
    },

    query() {
      this.current = 1;
      this.getList();
    },
    reset() {
      this.form = {};
      this.current = 1;
      this.getList();
    },
  },
};
</script>