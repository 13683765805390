var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Pane',{attrs:{"bottom":0,"showRemarks":false}},[_c('a-tabs',{attrs:{"tabBarStyle":{
        margin: '0',
      }},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},_vm._l(([
          { name: '所有档案', value: '1' },
          { name: '出借申请', value: '2' },
          { name: '出借记录', value: '3' },
        ]),function(item){return _c('a-tab-pane',{key:item.value,attrs:{"tab":item.name}})}),1)],1),_c('a-card',{staticClass:"container"},[(_vm.active === '1')?[_c('All')]:(_vm.active === '2')?[_c('LendApply')]:(_vm.active === '3')?[_c('LendLog')]:_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }