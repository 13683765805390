var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-form-model',{attrs:{"layout":"inline"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.query.apply(null, arguments)}}},[_c('a-row',[_c('a-col',{attrs:{"span":22}},[_c('a-form-model-item',[_c('a-input',{attrs:{"placeholder":"档案编号"},model:{value:(_vm.form.code),callback:function ($$v) {_vm.$set(_vm.form, "code", $$v)},expression:"form.code"}})],1),_c('a-form-model-item',[_c('a-input',{attrs:{"placeholder":"档案名称"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('a-form-model-item',[_c('a-cascader',{attrs:{"placeholder":"档案类型","options":_vm.typeList,"fieldNames":{
              label: 'name',
              value: 'value',
              children: 'children',
            }},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}})],1),_c('a-form-model-item',[_c('a-select',{staticStyle:{"width":"180px"},attrs:{"placeholder":"保密级别"},model:{value:(_vm.form.level),callback:function ($$v) {_vm.$set(_vm.form, "level", $$v)},expression:"form.level"}},_vm._l((_vm.levelList),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1),_c('a-form-model-item',[_c('a-select',{staticStyle:{"width":"180px"},attrs:{"placeholder":"移交部门"},model:{value:(_vm.form.transfereeDeptId),callback:function ($$v) {_vm.$set(_vm.form, "transfereeDeptId", $$v)},expression:"form.transfereeDeptId"}},_vm._l((_vm.organizationList),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(_vm._s(item.uniqueName))])}),1)],1),_c('a-form-model-item',[_c('a-select',{staticStyle:{"width":"180px"},attrs:{"placeholder":"档案出借状态"},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}},_vm._l((_vm.archiveBorrowStatusList),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1),_c('a-form-model-item',[_c('a-space',[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.query}},[_vm._v("查询")]),_c('a-button',{on:{"click":_vm.reset}},[_vm._v("重置")])],1)],1)],1),_c('a-col',{attrs:{"span":2}},[_c('div',{staticClass:"right",staticStyle:{"margin-top":"4px"}},[_c('Back',{on:{"refresh":_vm.getList}})],1)])],1)],1),_c('Padding'),_c('a-table',{attrs:{"bordered":"","dataSource":_vm.list,"loading":_vm.loading,"pagination":{
      total: _vm.total,
      current: _vm.current,
      pageSize: _vm.pageSize,
      showTotal: (total) => `共 ${total} 条记录`,
    }},on:{"change":_vm.onChange}},[_c('a-table-column',{attrs:{"title":"档案编号","data-index":"code"}}),_c('a-table-column',{attrs:{"title":"档案名称","data-index":"name"}}),_c('a-table-column',{attrs:{"title":"档案类型"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_vm._v(" "+_vm._s(_vm.getTypeText(text.type))+" ")]}}])}),_c('a-table-column',{attrs:{"title":"保密级别","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('DataDictFinder',{attrs:{"dictType":"secretLevel","dictValue":text.level}})]}}])}),_c('a-table-column',{attrs:{"title":"档案出借状态","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('DataDictFinder',{attrs:{"dictType":"arcBorrowArcStatus","dictValue":text.status,"iconType":"text"}})]}}])}),_c('a-table-column',{attrs:{"title":"出借人","data-index":"borrowerName","align":"center"}}),_c('a-table-column',{attrs:{"title":"出借部门","data-index":"deptName","align":"center"}}),_c('a-table-column',{attrs:{"title":"出借时间","data-index":"borrowedDate","align":"center"}}),_c('a-table-column',{attrs:{"title":"预计归还时间","data-index":"expectReturnDate","align":"center"}}),_c('a-table-column',{attrs:{"title":"实际归还时间","data-index":"actualReturnDate","align":"center"}}),_c('a-table-column',{attrs:{"title":"操作","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('a-space',[(text.status === 'borrowing' && text.status === 'overdue')?_c('a',{staticClass:"danger",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.revert(text)}}},[_vm._v("归还")]):_vm._e()])]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }